import React from "react";
import { Text } from "@chakra-ui/react";
import { FavoriteBadge } from "../../views/Contents/components/ContentBadges";

interface OwnProps {
    text: string;
}

export const H1 = ({ text }: OwnProps) => <Text fontSize="3xl">{text}</Text>;
export const H2 = ({ text }: OwnProps) => (
    <Text fontSize="2xl" fontWeight="semibold">
        {text}
    </Text>
);
export const H3 = ({ text }: OwnProps) => (
    <Text fontSize="xl" fontWeight="semibold">
        {text}
    </Text>
);
export const H4 = ({ text }: OwnProps) => (
    <Text fontSize="large" fontWeight="semibold">
        {text}
    </Text>
);
export const H3Gray = ({ text }: OwnProps) => (
    <Text fontSize="xl" textColor="gray.500">
        {text}
    </Text>
);
export const H2Error = ({ text }: OwnProps) => (
    <Text fontSize="2xl" fontWeight="semibold" textColor="red.600">
        {text}
    </Text>
);
export const CardTitle = ({ text, isFavorite }: OwnProps & {isFavorite?: boolean}) => (
    <Text
        fontSize="lg"
        textColor="CaptionText"
        fontWeight="semibold"
        noOfLines={2}
        textAlign="center"
    >
        { isFavorite && <FavoriteBadge /> }
        {text}
    </Text>
);
export const CardSubtitle = ({ text }: OwnProps) => (
    <Text fontSize="md" textColor="blackAlpha.600" noOfLines={1}>
        {text}
    </Text>
);

export const ErrorText = ({ text }: OwnProps) => (
    <Text fontSize="md" textColor="red.600">
        {text}
    </Text>
);
