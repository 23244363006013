import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { userState, userActions } from "./store/slices/user";
import Login from "./views/Login";
import LoadingMe from "./views/Login/LoadingMe";
import Main from "./views/Main";

function App() {
    const { isLoadingMe, user } = useSelector(userState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.loadMe());
    }, []);

    return (
        <>
            {isLoadingMe && <LoadingMe />}
            {!isLoadingMe && user ? <Main /> : <Login />}
        </>
    );
}

export default App;
