import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
} from "@chakra-ui/react";
import { ContentKnowledge } from "../../../common/types";
import { ErrorText } from "../../../common/components/Texts";

interface OwnProps {
    isLoading: boolean;
    hasError: boolean;
    onSave: (data: Pick<ContentKnowledge, "question" | "answer">) => void;
}

const KnowledgeCardForm = ({ isLoading, hasError, onSave }: OwnProps) => {
    const [qa, setQA] = useState({ answer: "", question: "" });

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="question">Question: </FormLabel>
                <Input
                    id="question"
                    type="text"
                    onChange={(e) => {
                        setQA({
                            ...qa,
                            question: e.target.value,
                        });
                    }}
                ></Input>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="answer">Answer:</FormLabel>
                <Textarea
                    id="answer"
                    onChange={(e) => {
                        setQA({
                            ...qa,
                            answer: e.target.value,
                        });
                    }}
                ></Textarea>
            </FormControl>
            <FormControl
                marginTop={5}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
            >
                <Button
                    size="md"
                    colorScheme="green"
                    isLoading={isLoading}
                    onClick={() => {
                        onSave({
                            ...qa,
                        });
                    }}
                    disabled={
                        !(
                            qa.question.length > 0 &&
                            qa.answer.length > 0
                        )
                    }
                >
                    Save
                </Button>
            </FormControl>
            {!isLoading && hasError && (
                <FormControl>
                    <ErrorText text="An error has occurred, please try again." />
                </FormControl>
            )}
        </>
    );
};
export default KnowledgeCardForm;
