import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CreateSubject, UpdateSubject } from "../../api/subject";
import { Subject } from "../../common/types";

interface OwnState {
    hasErrorLoadingSubjects: boolean;
    hasErrorSaving: boolean;
    isLoadingSubjects: boolean;
    isSaving: boolean;
    subjects: Subject[];
}

const initialState: OwnState = {
    hasErrorLoadingSubjects: false,
    hasErrorSaving: false,
    isLoadingSubjects: false,
    isSaving: false,
    subjects: [],
};

const slice = createSlice({
    name: "subject",
    initialState,
    reducers: {
        deleteSubject: (state, _) => {
            state.isLoadingSubjects = true;
            state.hasErrorLoadingSubjects = false;
        },
        deleteSubjectCompleted: (state, action: PayloadAction<string>) => {
            state.isLoadingSubjects = false;
            state.subjects = [...state.subjects].filter(s => s._id !== action.payload);
        },
        deleteSubjectFailed: (state, _) => {
            state.isLoadingSubjects = false;
        },
        loadSubjects: (state, _: PayloadAction<string>) => {
            state.isLoadingSubjects = true;
            state.hasErrorLoadingSubjects = false;
        },
        loadSubjectsCompleted: (state, action: PayloadAction<Subject[]>) => {
            state.isLoadingSubjects = false;
            state.hasErrorLoadingSubjects = false;
            state.subjects = action.payload;
        },
        loadSubjectsFailed: (state) => {
            state.isLoadingSubjects = false;
            state.hasErrorLoadingSubjects = true;
        },
        saveSubject: (state, action: PayloadAction<CreateSubject>) => {
            state.isSaving = true;
            state.hasErrorSaving = false;
        },
        saveSubjectCompleted: (state, action: PayloadAction<Subject>) => {
            state.isLoadingSubjects = true;
            state.isSaving = false;
            state.hasErrorSaving = false;
            state.subjects.push(action.payload);
            state.isLoadingSubjects = false;
        },
        saveSubjectFailed: (state) => {
            state.isSaving = false;
            state.hasErrorSaving = true;
        },
        updateSubject: (state, _: PayloadAction<UpdateSubject>) => {
            state.isSaving = true;
            state.hasErrorSaving = false;
        },
        updateSubjectCompleted: (state, action: PayloadAction<Subject>) => {
            state.isLoadingSubjects = true;
            const updated = (state.subjects.filter(s => s._id !== action.payload._id));
            updated.push(action.payload);
            state.subjects = [...updated];
            state.isSaving = false;
            state.hasErrorSaving = false;
            state.isLoadingSubjects = false;
        },
        updateSubjectFailed: (state) => {
            state.isSaving = false;
            state.hasErrorSaving = true;
        }
    }
});

export const subjectActions = slice.actions;

export const subjectState = (state: RootState) => state.subject;

export default slice.reducer;