import {useState } from "react";
import { Method, REACT_APP_API_URL } from "../../api/base";
import { Callbacks, RequestState } from "./types";

type CallFunction = (payload?: object) => void;

export default function useLazyRequest<T>(method: Method, relativeUrl: string, callbacks?: Callbacks<T>): [RequestState<T>, CallFunction] {
    const [status, setStatus] = useState<RequestState<T>>({
        isLoading: false,
        hasError: false,
        data: null,
    });

    const call = (payload?: object) => {
        setStatus({ ...status, isLoading: true });

        fetch(`${REACT_APP_API_URL}${relativeUrl}`,
            {
                method,
                credentials: 'include',
                body: payload ? JSON.stringify(payload) : undefined,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
            .then(res => res.json())
            .then(data => {
                setStatus({
                    isLoading: false,
                    hasError: false,
                    data: data,
                });
                if (callbacks && callbacks.onSuccess) {
                    callbacks.onSuccess(data);
                }
            })
            .catch(err => {
                console.error("Something went wrong while lazy requesting", relativeUrl, ":", err.message);
                setStatus({
                    isLoading: false,
                    hasError: true,
                    data: null,
                });
                if (callbacks && callbacks.onError) {
                    callbacks.onError(err);
                }
            });
    };

    return [
        status,
        call
    ];
}