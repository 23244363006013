import React, { useContext, useEffect, useState } from "react";
import { Button, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import ContentContext from "../../../common/context/ContentContext";
import useRequest from "../../../common/hooks/useRequest";
import { ContentKnowledge } from "../../../common/types";
import { ErrorText, H2, H3Gray } from "../../../common/components/Texts";
import KnowledgeCardForm from "./KnowledgeCardForm";
import InputDialog from "../../../common/components/InputDialog";
import useLazyRequest from "../../../common/hooks/useLazyRequest";
import KnowledgeCard from "./KnowledgeCard";

const ContentKnowledgeViewer = () => {
    const content = useContext(ContentContext);
    const {
        isOpen: isFormOpen,
        onOpen: onOpenForm,
        onClose: onCloseForm,
    } = useDisclosure();
    const { isLoading, data, hasError } = useRequest<ContentKnowledge[]>(
        "GET",
        `/knowledge/contents/${content!._id}`
    );
    const [knowledge, setKnowledge] = useState<ContentKnowledge[]>([]);
    const [hasSaved, setHasSaved] = useState(false);
    const [
        {
            isLoading: isCreateLoading,
            data: createResponse,
            hasError: hasCreationError,
        },
        createKnowledge,
    ] = useLazyRequest<ContentKnowledge>(
        "POST",
        `/knowledge/contents/${content!._id}`
    );

    useEffect(() => {
        if (data) {
            setKnowledge([...data]);
        }
    }, [data]);

    useEffect(() => {
        if (hasSaved && !isCreateLoading) {
            setHasSaved(false);
            if (createResponse) {
                setKnowledge([createResponse, ...knowledge]);
                onCloseForm();
            }
        }
    }, [hasSaved, isCreateLoading, createResponse]);

    const onSave = (data: Pick<ContentKnowledge, "question" | "answer">) => {
        setHasSaved(true);
        createKnowledge({
            ...data,
            content: content!._id,
            subject: content!.subject,
        });
    };

    return (
        <>
            {content && (
                <VStack spacing={4}>
                    <Text>Try to answer yourself, when ready, press 'Reveal Answer'</Text>
                    <InputDialog
                        body={
                            <KnowledgeCardForm
                                isLoading={isCreateLoading}
                                hasError={hasCreationError}
                                onSave={onSave}
                            />
                        }
                        isOpen={isFormOpen}
                        onClose={onCloseForm}
                        header={<H2 text="Create Knowledge Card" />}
                    />
                    <Button
                        variant="outline"
                        colorScheme="purple"
                        size="sm"
                        onClick={onOpenForm}
                        alignSelf="flex-end"
                        isLoading={isCreateLoading}
                    >
                        Create Knowledge Card
                    </Button>
                    {isLoading && <Spinner />}
                    {!isLoading && hasError && (
                        <ErrorText text="Something went wrong getting the knowledge cards" />
                    )}
                    {!isLoading && !hasError && !knowledge.length && (
                        <H3Gray text="No knowledge cards have been created" />
                    )}
                    {!isLoading && knowledge.length > 0 && (
                        <VStack spacing={2} width="100%">
                            {knowledge.map((k) => (
                                <KnowledgeCard key={k._id} knowledge={k} />
                            ))}
                        </VStack>
                    )}
                </VStack>
            )}
        </>
    );
};

export default ContentKnowledgeViewer;
