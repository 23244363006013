import React from "react";
import { Box } from "@chakra-ui/react";
import { CardHeight, CardWidth } from "../types";

interface OwnProps {
    children: React.ReactNode;
}

export const Card = ({ children }: OwnProps) => (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w={CardWidth}
        h={CardHeight}
        borderWidth="1px"
        borderRadius="lg"
        p="2"
        overflow="hidden"
    >
        {children}
    </Box>
);

export const DottedCard = ({ children }: OwnProps) => (
    <Box
        w={CardWidth}
        h={CardHeight}
        borderWidth="2px"
        borderStyle="dashed"
        borderRadius="lg"
    >
        {children}
    </Box>
);
