import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Center, VStack } from "@chakra-ui/react";
import TopBar from "./components/TopBar";
import Subjects from "../Subjects";
import Contents from "../Contents";
import Content from "../Content";
import SearchResults from "../Contents/SearchResults";
import RecapSubject from "../Knowledge/RecapSubject";
import ContentMap from "../Contents/ContentMap";

const Main = () => {
    return (
        <Center>
            <VStack marginTop="20px" width="96%">
                <BrowserRouter>
                    <TopBar />
                    <Routes>
                        <Route path="/" element={<Subjects />} />
                        <Route
                            path="/subjects/:id/contents"
                            element={<Contents />}
                        />
                        <Route path="/subjects/:id/map" element={<ContentMap />} />
                        <Route path="/contents/:id" element={<Content />} />
                        <Route path="/contents" element={<SearchResults />} />
                        <Route
                            path="/knowledge/recap/:subjectId"
                            element={<RecapSubject />}
                        />
                    </Routes>
                </BrowserRouter>
            </VStack>
        </Center>
    );
};

export default Main;
