import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { Card } from "../../../common/components/Cards";
import { Content, WebsiteContent } from "../../../common/types";
import { FavoriteBadge, UrlBadge } from "./ContentBadges";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleQuestion } from "@fortawesome/free-solid-svg-icons";

export const ContentCard = ({ content }: { content: Content }) => {
    const updateDate = content.updatedAt
        ? new Date(content.updatedAt)
        : new Date(content.createdAt);
    const subtitle = `Last update: ${updateDate.toLocaleDateString()}`;

    return (
        <Card>
            <Link to={`/contents/${content._id}`}>
                <Text
                    fontSize="md"
                    textColor="gray.700"
                    fontWeight="semibold"
                    noOfLines={2}
                    textAlign="center"
                >
                    {content.isFavorite && <FavoriteBadge />}
                    {content.title}
                </Text>
            </Link>
            <HStack>
                <Text fontSize="md" textColor="blackAlpha.600" noOfLines={1}>
                    {subtitle}
                </Text>
                {content.openQuestions && content.openQuestions.length > 0 && (
                    <FontAwesomeIcon
                        icon={faFileCircleQuestion}
                        color="gray"
                        title="This content has open questions"
                    />
                )}
            </HStack>
            {content.contentType === "website" && (
                <UrlBadge url={(content as WebsiteContent).url!} />
            )}
        </Card>
    );
};
