import React from "react";
import { Button } from "@chakra-ui/react";
import { faArrowsToCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ContentMapButton = ({ subjectId }: { subjectId: string }) => {
    return (
        <Link to={`/subjects/${subjectId}/map`}>
        <Button variant="outline" colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faArrowsToCircle} />}>
            Content Map
        </Button>
        </Link>
    );
};

export default ContentMapButton;
