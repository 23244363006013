import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import InputSubject from "../../Subjects/components/InputSubject";
import { CreateSubject } from "../../../api/subject";
import { useDispatch, useSelector } from "react-redux";
import { userState } from "../../../store/slices/user";
import { subjectActions, subjectState } from "../../../store/slices/subject";
import { CardHeight, CardWidth } from "../../../common/types";

const CreateSubjectCard = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { user } = useSelector(userState);
    const { isSaving, hasErrorSaving } = useSelector(subjectState);
    const dispatch = useDispatch();
    const [hasSaved, setHasSaved] = useState(false);
    const toast = useToast();

    const onSave = (data: Omit<CreateSubject, "user">) => {
        dispatch(subjectActions.saveSubject({ ...data, user: user!._id }));
        setHasSaved(true);
    };

    useEffect(() => {
        if (hasSaved) {
            if (isSaving) {
                return;
            }

            setHasSaved(false);
            if (hasErrorSaving) {
                toast({
                    title: "Cannot save subject.",
                    description:
                        "We've encountered an error creating your subject. Please try again",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Subject created.",
                    description:
                        "We've successfully created your new subject.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        }
    }, [hasSaved, isSaving, hasErrorSaving]);

    return (
        <>
            <InputSubject
                isOpen={isOpen}
                isSubmitting={isSaving}
                onClose={onClose}
                onSave={onSave}
            />
            <Box
                w={CardWidth}
                h={CardHeight}
                borderWidth="2px"
                borderStyle="dashed"
                borderRadius="lg"
            >
                <Center h="120px">
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                        size="lg"
                        colorScheme="green"
                        variant="ghost"
                        onClick={onOpen}
                    >
                        Create Subject
                    </Button>
                </Center>
            </Box>
        </>
    );
};

export default CreateSubjectCard;
