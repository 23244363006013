import React, { useContext, useEffect, useState } from "react";
import { CardSubtitle, H4 } from "../../../common/components/Texts";
import { ContentRelationship } from "cordia-common/lib/types";
import {
    Box,
    Button,
    Flex,
    Input,
    Spacer,
    Spinner,
    Text,
} from "@chakra-ui/react";
import { InternalLink } from "../../../common/components/Links";
import useDynamicRequest from "../../../common/hooks/useDynamicRequest";
import { debounce } from "../../../common/utils";
import { Content, SearchResult } from "../../../common/types";
import useLazyRequest from "../../../common/hooks/useLazyRequest";
import { useDispatch } from "react-redux";
import { contentActions } from "../../../store/slices/content";
interface OtherContent extends ContentRelationship {
    _id?: string;
    otherContent: SearchResult;
}

const ContentLinker = ({
    id,
    defaultLinked,
}: {
    id: string;
    defaultLinked: Required<OtherContent>[];
}) => {
    let relationship: OtherContent | undefined;
    const [related, setRelated] = useState<OtherContent[]>(defaultLinked);
    const [showSelectContent, setShowSelectContent] = useState(false);
    const [matched, setMatched] = useState<SearchResult[]>([]);
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const [
        {
            isLoading: isSearching,
            hasError: hasErrorSearching,
            data: searchResults,
        },
        callSearch,
    ] = useDynamicRequest<SearchResult[]>();

    const onRelatingSuccess = (data: Content) => {
        if (relationship) {
            const updated = [...related];
            updated.push({ ...relationship });
            setRelated(updated);
            setSearchText("");
            setShowSelectContent(false);
            setMatched([]);
            dispatch(contentActions.updateContentCompleted(data));
        }
    };

    const [
        { isLoading: isUpdating, hasError: hasErrorUpdating },
        callLinkContents,
    ] = useLazyRequest<Content>("POST", `/content/${id}/relate`, { onSuccess: onRelatingSuccess });

    useEffect(() => {
        if (searchText && searchText.length > 3) {
            callSearch("GET", `/content/search?q=${searchText}`);
        } else {
            setMatched([]);
        }
    }, [searchText]);

    useEffect(() => {
        if (searchResults) {
            setMatched(searchResults);
        }
    }, [searchResults]);

    const linkContent = (otherContent: SearchResult) => {
        relationship = {
            relationship: "isRelatedTo",
            otherContent,
        };
        callLinkContents({
            relationship: "isRelatedTo",
            otherContent: otherContent._id,
        });
    };

    return (
        <>
            <Flex alignItems="center">
                <Spacer />
                <Button
                    variant="outline"
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                        setShowSelectContent(true);
                    }}
                >
                    Link Content
                </Button>
            </Flex>
            <Box display="flex" flexDirection="column" justifyContent="start">
                {related.length === 0 && (
                    <CardSubtitle text="No related content yet" />
                )}
                {related.map((r) => (
                    <Box key={r.otherContent._id} marginBottom={4}>
                        <InternalLink
                            url={`/contents/${r.otherContent._id}`}
                            text={r.otherContent.title}
                        />
                    </Box>
                ))}
            </Box>
            {showSelectContent && (
                <>
                    {isUpdating && <Spinner />}
                    {!isUpdating && hasErrorUpdating && (
                        <Text>
                            We cannot link your related content. Please try
                            again
                        </Text>
                    )}

                    <Box display="flex" alignItems="center" marginTop={3}>
                        <Input
                            type="text"
                            size="sm"
                            placeholder="Search other content"
                            onChange={(e) => {
                                debounce(() => {
                                    setSearchText(e.target.value);
                                }, 1000);
                            }}
                        />
                        <Button
                            size="sm"
                            variant="outline"
                            colorScheme="red"
                            onClick={() => {
                                setShowSelectContent(false);
                                setMatched([]);
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>

                    <>
                        {isSearching && <Spinner />}
                        {!isSearching && hasErrorSearching && (
                            <Text>Something went wrong. Please try again</Text>
                        )}
                        {!isSearching &&
                            !hasErrorSearching &&
                            matched.length > 0 && (
                                <Box
                                    marginTop={1}
                                    p={2}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    w="84%"
                                >
                                    {matched
                                        .filter((m) => m._id !== id)
                                        .map((m) => (
                                            <Text
                                                key={m._id}
                                                textColor="blue.700"
                                                cursor="pointer"
                                                onClick={() => {
                                                    linkContent(m);
                                                }}
                                                marginBottom={2}
                                            >
                                                {m.title}
                                            </Text>
                                        ))}
                                </Box>
                            )}
                    </>
                </>
            )}
        </>
    );
};

export default ContentLinker;
