import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faStar } from "@fortawesome/free-solid-svg-icons";
import { Badge, Link } from "@chakra-ui/react";

export const FavoriteBadge = () => (
    <Badge colorScheme="yellow">
        <FontAwesomeIcon icon={faStar} />
    </Badge>
);

export const UrlBadge = ({ url }: { url: string }) => (
    <Badge colorScheme="blue" variant="outline">
        <Link href={url} target="_blank"><FontAwesomeIcon icon={faExternalLink} /> Go to bookmark</Link>
    </Badge>
);
