import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";

interface OwnProps {
    children: React.ReactElement;
    header: string;
    isOpen: boolean;
    onClose: () => void;
}

const PopMenu = ({ isOpen, onClose, children, header }: OwnProps) => {
    return (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{header}</DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default PopMenu;
