import React, { useContext, useRef, useState } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Textarea,
    useMediaQuery,
} from "@chakra-ui/react";
import { Content } from "../../../common/types";
import ReactQuill from "react-quill";
import { QuillToolbarOptions } from "../../../common/editor";
import { ExternalLink } from "../../../common/components/Links";
import { ContentActionsButtons } from "../../Contents/components/ContentActions";
import ContentContext from "../../../common/context/ContentContext";
import { debounce } from "../../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export type DataInput = Pick<Content, "title" | "content" | "url">;
const DEBOUNCE_TIME = 60_000;

interface OwnProps {
    titleRef: React.MutableRefObject<string>;
    contentRef: React.MutableRefObject<string>;
    keyConceptsRef: React.MutableRefObject<string>;
    openQuestionsRef: React.MutableRefObject<string>;
    isAllSavedRef: React.MutableRefObject<boolean>;
    onLinkContent: () => void;
    patchContent: (payload?: object) => void;
}

const ContentForm = ({
    titleRef,
    contentRef,
    keyConceptsRef,
    openQuestionsRef,
    isAllSavedRef,
    onLinkContent,
    patchContent,
}: OwnProps) => {
    const content = useContext(ContentContext);
    const changesNotified = useRef(false);
    const [showMetaContent, setShowMetaContent] = useState(
        !!content?.keyConcepts || !!content?.openQuestions
    );
    const [isAtLeastMedium] = useMediaQuery("(min-width: 48em)");

    const notifyChanges = () => {
        if (!changesNotified.current) {
            changesNotified.current = true;
            debounce(() => {
                patchContent({
                    title: titleRef.current,
                    content: contentRef.current,
                    keyConcepts: keyConceptsRef.current,
                    openQuestions: openQuestionsRef.current,
                });
                changesNotified.current = false;
            }, DEBOUNCE_TIME);
        }
    };

    const onChangeText = (
        { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ref: React.MutableRefObject<string>
    ) => {
        ref.current = target.value;
        isAllSavedRef.current = false;
        notifyChanges();
    };

    const MetaContentAccordion = () => (
        <Accordion allowToggle defaultIndex={0}>
            <AccordionItem border={0}>
                <>
                    <AccordionButton>
                        <FormLabel fontSize="xl" flex={1}>
                            Key concepts {content?.keyConcepts && "*"}
                        </FormLabel>
                        <AccordionIcon />
                    </AccordionButton>
                </>
                <AccordionPanel>
                    <Textarea
                        placeholder="Write down main ideas, important people or dates, key points"
                        rows={20}
                        resize="none"
                        defaultValue={keyConceptsRef.current}
                        onChange={(e) => {
                            onChangeText(e, keyConceptsRef);
                        }}
                    />
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
                <>
                    <AccordionButton>
                        <FormLabel fontSize="xl" flex={1}>
                            Open Questions {content?.openQuestions && "*"}
                        </FormLabel>
                        <AccordionIcon />
                    </AccordionButton>
                </>
                <AccordionPanel>
                    <Textarea
                        placeholder="Do I have to search for something else and go beyond this content?"
                        rows={20}
                        resize="none"
                        defaultValue={openQuestionsRef.current}
                        onChange={(e) => {
                            onChangeText(e, openQuestionsRef);
                        }}
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );

    const ToggleMetaContentButton = () => (
        <Button
            variant="ghost"
            leftIcon={
                <FontAwesomeIcon
                    icon={showMetaContent ? faChevronLeft : faChevronRight}
                />
            }
            onClick={() => {
                setShowMetaContent(showMetaContent ? false : true);
            }}
        >
            Key concepts & Questions
        </Button>
    );

    return (
        <Box marginTop={2}>
            <FormControl isRequired marginTop={10} marginBottom={5}>
                <Input
                    variant="flushed"
                    fontSize={{ base: "xl", md: "3xl" }}
                    type="text"
                    placeholder="Add a Title"
                    _placeholder={{
                        color: "black",
                        fontWeight: "bold",
                    }}
                    isRequired
                    defaultValue={content!.title}
                    onChange={(e) => {
                        onChangeText(e, titleRef);
                    }}
                />
            </FormControl>
            <FormControl>
                {content!.url && <ExternalLink url={content!.url} />}
            </FormControl>
            <FormControl>
                <ContentActionsButtons onLinkContent={onLinkContent} />
            </FormControl>
            <ToggleMetaContentButton />
            <HStack w="100%" alignItems="flex-start">
                <Box
                    w={isAtLeastMedium ? "50%" : "96%"}
                    hidden={!showMetaContent}
                >
                    <MetaContentAccordion />
                </Box>
                <Box
                    w={isAtLeastMedium && showMetaContent ? "50%" : "96%"}
                    hidden={!isAtLeastMedium && showMetaContent}
                >
                    <FormControl>
                        <FormLabel fontSize="2xl">Notes</FormLabel>
                        <ReactQuill
                            theme="snow"
                            defaultValue={content!.content}
                            onChange={(v) => {
                                contentRef.current = v;
                                notifyChanges();
                            }}
                            style={{ height: "50vh", marginBottom: "6rem" }}
                            modules={{
                                toolbar: QuillToolbarOptions,
                            }}
                        />
                    </FormControl>
                </Box>
            </HStack>
        </Box>
    );
};

export default ContentForm;
