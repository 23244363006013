import { User } from "../common/types";
import callApi from "./base";

export interface LoginResponse {
    token: string;
    user: User;
}


export const postLogin = ({ email, password }: { email: string, password: string }): Promise<LoginResponse> =>
    callApi('POST', '/login', { email, password });

export const getMe = (): Promise<User> => callApi('GET', '/me');

export const postLogout = (): Promise<void> => callApi('POST', '/logout');
