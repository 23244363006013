import { Button } from "@chakra-ui/react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ContentContext from "../../../common/context/ContentContext";

const RelatedContentButton = ({
    onLinkContent,
}: {
    onLinkContent: () => void;
}) => {
    const content = useContext(ContentContext);
    const [text, setText] = useState("");
    useEffect(() => {
        let _text = "Related Content";
        if (content) {
            _text += content.relationships.length > 0 ? ` (${content.relationships.length})` : "";
        }

        setText(_text);
    }, [content]);

    return (
        <Button
            colorScheme="blue"
            variant="outline"
            leftIcon={<FontAwesomeIcon icon={faBars} />}
            onClick={onLinkContent}
        >
            { text }
        </Button>
    );
};

export default RelatedContentButton;
