import React, { useContext } from "react";
import {
    Button,
    ButtonGroup,
    Text,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputDialog from "../../../common/components/InputDialog";
import ContentContext from "../../../common/context/ContentContext";
import useDynamicRequest from "../../../common/hooks/useDynamicRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { contentActions } from "../../../store/slices/content";

const DialogueForm = ({
    handleCancel,
    handleDelete,
    title,
}: {
    handleCancel: () => void;
    handleDelete: () => void;
    title: string;
}) => {
    return (
        <VStack spacing={4}>
            <Text fontSize="large">
                You're about to delete <b>{title}</b>
            </Text>
            <Text fontSize="large">This action cannot be undone.</Text>
            <ButtonGroup>
                <Button variant="outline" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete}>
                    Yes, delete content
                </Button>
            </ButtonGroup>
        </VStack>
    );
};

const DeleteButton = () => {
    const content = useContext(ContentContext);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [_, callDelete] = useDynamicRequest<{}>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDelete = () => {
        callDelete("DELETE", `/content/${content!._id}`, undefined, {
            onSuccess: (_) => {
                onClose();
                dispatch(contentActions.deleteContentCompleted(content!._id));
                navigate(`/subjects/${content!.subject}/contents`, {
                    replace: true,
                });
            },
        });
    };

    return (
        <>
            {content && (
                <>
                    <InputDialog
                        body={
                            <DialogueForm
                                handleCancel={onClose}
                                handleDelete={handleDelete}
                                title={content.title}
                            />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                        header={<Text color="red.800">Delete content?</Text>}
                    />
                    <Button
                        colorScheme="red"
                        variant="outline"
                        leftIcon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={onOpen}
                    >
                        Delete
                    </Button>
                </>
            )}
        </>
    );
};

export default DeleteButton;
