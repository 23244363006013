import { PayloadAction } from "@reduxjs/toolkit";
import {call, put, takeLatest, takeLeading} from "redux-saga/effects";
import { CreateSubject, deleteSubject, getSubjects, patchSubject, postSubject, UpdateSubject } from "../../api/subject";
import { Subject } from "../../common/types";
import { subjectActions } from "../slices/subject";

function* callDeleteSubject(action: PayloadAction<string>) {
    try {
        yield call(deleteSubject, action.payload);
        yield put(subjectActions.deleteSubjectCompleted(action.payload));
    } catch(err) {
        console.error("Error deleting subject", err);
    }
}

function* callGetSubjects(action: PayloadAction<string>) {
    try {
        const result: Subject[] = yield call(getSubjects, action.payload);
        yield put(subjectActions.loadSubjectsCompleted(result));
    } catch (err) {
        yield put(subjectActions.loadSubjectsFailed());
    }
}

function* callPostSubject(action: PayloadAction<CreateSubject>) {
    try {
        const result: Subject = yield call(postSubject, action.payload);
        yield put(subjectActions.saveSubjectCompleted(result));
    } catch (err) {
        yield put(subjectActions.saveSubjectFailed());
    }
}

function* callPatchSubject(action: PayloadAction<UpdateSubject>) {
    try {
        const result: Subject = yield call(patchSubject, action.payload);
        yield put(subjectActions.updateSubjectCompleted(result));
    } catch (err) {
        yield put(subjectActions.updateSubjectFailed());
    }
}

export default function* subjectSaga() {
    yield takeLeading(subjectActions.deleteSubject, callDeleteSubject);
    yield takeLeading(subjectActions.loadSubjects.type, callGetSubjects);
    yield takeLeading(subjectActions.saveSubject.type, callPostSubject);
    yield takeLatest(subjectActions.updateSubject.type, callPatchSubject);
}