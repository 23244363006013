import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Spinner, VStack } from "@chakra-ui/react";
import SubjectCard from "./components/SubjectCard";
import CreateSubjectCard from "./components/CreateSubjectCard";
import { useDispatch, useSelector } from "react-redux";
import { subjectActions, subjectState } from "../../store/slices/subject";
import { userState } from "../../store/slices/user";
import { H1, H2, H2Error, H3 } from "../../common/components/Texts";
import { Subject } from "../../common/types";

const Subjects = () => {
    const [featured, setFeatured] = useState<Subject[]>([]);
    const dispatch = useDispatch();
    const { isLoadingSubjects, subjects, hasErrorLoadingSubjects } =
        useSelector(subjectState);
    const { user } = useSelector(userState);
    const dayOfWeek = new Date().getDay();

    useEffect(() => {
        dispatch(subjectActions.loadSubjects(user!._id));
    }, []);

    useEffect(() => {
        if (subjects.length > 0) {
            const featToday = subjects.filter(({ featured }) =>
                featured.includes(dayOfWeek)
            );
            setFeatured([...featToday]);
        }
    }, [subjects]);

    return (
        <>
            <H1 text="Subjects" />
            {isLoadingSubjects && <Spinner />}
            {!isLoadingSubjects && hasErrorLoadingSubjects && (
                <H2Error text="Something went wrong :(" />
            )}

            {!isLoadingSubjects && !hasErrorLoadingSubjects && subjects && (
                <VStack spacing={10} width="100%">
                    {featured.length > 0 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                        >
                            <H2 text="Featured Today ⭐️" />
                            <SimpleGrid
                                columns={{ base: 1, md: 2, lg: 3 }}
                                spacing={4}
                            >
                                {featured
                                    .map((subject) => (
                                        <SubjectCard
                                            key={subject._id}
                                            subject={subject}
                                        />
                                    ))}
                            </SimpleGrid>
                        </Box>
                    )}

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <H3 text={ featured.length > 0 ? "Other Subjects" : ""} />
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} width="100%">
                            <CreateSubjectCard />
                            {subjects
                                .filter(
                                    ({ featured }) =>
                                        !featured.includes(dayOfWeek)
                                )
                                .map((subject) => (
                                    <SubjectCard
                                        key={subject._id}
                                        subject={subject}
                                    />
                                ))}
                        </SimpleGrid>
                    </Box>
                </VStack>
            )}
        </>
    );
};

export default Subjects;
