import React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
} from "reactflow";

import "reactflow/dist/style.css";

interface OwnProps {
    nodes: any[];
    edges: any[];
}

const ContentNodesMap = (props: OwnProps) => {
    const navigate = useNavigate();
    const [nodes, _setNodes, onNodesChange] = useNodesState([...props.nodes]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([...props.edges]);

    const onConnect = useCallback(
        (params: any) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeDoubleClick={(e: any) => {
                const { id } = e.target.dataset;
                if (id) {
                    navigate(`/contents/${id}`);
                }
            }}
        >
            <Background />
        </ReactFlow>
    );
};

export default ContentNodesMap;
