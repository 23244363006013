import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";

interface OwnProps {
    body: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    header: React.ReactElement;
    isFullSize?: boolean
}


const InputDialog = ({ body, isOpen, onClose, header, isFullSize }: OwnProps) => {
    return (
        <>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={ isFullSize ? "full" : { base: '300px', md: "3xl" }} closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{header}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{body}</ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default InputDialog;
