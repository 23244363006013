import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, Input, Spinner } from "@chakra-ui/react";
import { WebsiteContent } from "../../../common/types";
import { H2Error } from "../../../common/components/Texts";
import { debounce } from "../../../common/utils";
import ReactQuill from "react-quill";
import { QuillToolbarOptions } from "../../../common/editor";

export type WebDataInput = Pick<WebsiteContent, "title" | "content" | "url">;

interface OwnProps {
    dataRef: React.MutableRefObject<WebDataInput>;
}

const isValidUrl = (urlString: string) => {
    var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
};

const WebContentForm = ({ dataRef }: OwnProps) => {
    const [data, setLocalData] = useState<WebDataInput>({ title: "", content: "", url: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ hasError: false, message: "" });

    useEffect(() => {
        if (data.url) {
            setIsLoading(true);
            setError({ hasError: false, message: "" });

            // After 5 secs without response abort the call
            const abortController = new AbortController();
            const timeoutId = setTimeout(() => abortController.abort(), 5000);

            fetch(process.env.REACT_APP_SCRAPER_URL || "http://127.0.0.1:5001", {
                signal: abortController.signal,
                method: "POST",
                body: JSON.stringify({ url: data.url })
            })
                .then((response) => {
                    clearTimeout(timeoutId);
                    if (response.status !== 200) {
                        throw new Error("Website returned an invalid response");
                    }
                    return response.json();
                })
                .then((webContent: {title: string, description: string}) => {
                    setLocalData({...data, title: webContent.title, content: webContent.description });
                    dataRef.current = {...data, title: webContent.title, content: webContent.description };
                    setIsLoading(false);
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setError({ hasError: true, message: err.message });
                });
        }
    }, [data.url]);

    return (
        <form>
            <FormControl isRequired>
                <Input
                    size="lg"
                    type="text"
                    placeholder="Enter the URL"
                    _placeholder={{
                        color: "black",
                        fontWeight: "bold",
                    }}
                    isRequired
                    autoFocus
                    defaultValue={data.url}
                    onChange={(e) => {
                        const text = e.target.value;
                        if (isValidUrl(text)) {
                            debounce(() => {
                                const updated = { ...data, url: e.target.value};
                                setLocalData({ ...updated });
                                dataRef.current = updated;
                            }, 1000);
                        } else {
                            setError({
                                hasError: true,
                                message: "Not a valid URL",
                            });
                        }
                    }}
                />
            </FormControl>
            <Box>
                {isLoading && <Spinner />}
                {!isLoading && error.hasError && (
                    <H2Error text={error.message} />
                )}
                {data.url && !isLoading && !error.hasError && data && (
                    <>
                        <FormControl isRequired marginBottom="10px">
                            <Input
                                variant="flushed"
                                size="lg"
                                type="text"
                                placeholder="Add a Title"
                                _placeholder={{
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                isRequired
                                autoFocus
                                defaultValue={data.title}
                                onChange={(e) => {
                                    const updated = { ...data, title: e.target.value };
                                    setLocalData({...updated});
                                    dataRef.current = updated;
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <ReactQuill
                                theme="snow"
                                value={data.content}
                                onChange={(v) => {
                                    const updated = { ...data, content: v };
                                    setLocalData({...updated});
                                    dataRef.current = updated;
                                }}
                                style={{ height: "70vh", marginBottom: "6rem" }}
                                modules={{
                                    toolbar: QuillToolbarOptions,
                                }}
                            />
                        </FormControl>
                    </>
                )}
            </Box>
        </form>
    );
};

export default WebContentForm;
