import React from "react";
import { FormControl, Input } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "../styles/quill.cordia.css";
import { Content } from "../../../common/types";

const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ header: [1, 2, 3, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
    ["link", "image"],
];

export type NoteDataInput = Pick<Content, 'title' | 'content'>

interface OwnProps {
    dataRef: React.MutableRefObject<NoteDataInput>;
}

const NoteContentForm = ({
    dataRef,
}: OwnProps) => {

    return (
        <form>
            <FormControl isRequired marginBottom="10px">
                <Input
                    variant="flushed"
                    size="lg"
                    type="text"
                    placeholder="Add a Title"
                    _placeholder={{ color: "black", fontWeight: "bold" }}
                    isRequired
                    autoFocus
                    defaultValue=""
                    onChange={(e) => { dataRef.current = {...dataRef.current, title: e.target.value}; }}
                />
            </FormControl>
            <FormControl>
                <ReactQuill
                    theme="snow"
                    defaultValue=""
                    onChange={(v) => {
                        dataRef.current = {...dataRef.current, content: v};
                    }}
                    style={{ height: "70vh", marginBottom: "6rem" }}
                    modules={{
                        toolbar: toolbarOptions,
                    }}
                />
            </FormControl>
        </form>
    );
};

export default NoteContentForm;
