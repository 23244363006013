import React, { useEffect, useState } from "react";
import { Spinner, VStack } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { CardSubtitle, CardTitle, H1, H2Error } from "../../common/components/Texts";
import useDynamicRequest from "../../common/hooks/useDynamicRequest";
import { SearchResult, Subject } from "../../common/types";
import { Card } from "../../common/components/Cards";
import DisplayGrid from "../../common/components/DisplayGrid";

const SearchResults = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [{ isLoading, data, hasError }, getResults] =
        useDynamicRequest<SearchResult[]>();
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (queryParams.has("s") && query !== queryParams.get("s")) {
            const searchParam = queryParams.get("s");
            setQuery(searchParam!);
            getResults("GET", `/content/search?q=${searchParam}`);
        }
    }, [queryParams]);

    useEffect(() => {}, [data]);

    return (
        <VStack width="100%">
            <H1 text={`Displaying results for "${query}"`} />
            {isLoading && <Spinner />}
            {hasError && (
                <H2Error text="Something went wrong. Please try again" />
            )}
            {data && data.length > 0 && (
                <DisplayGrid>
                    {data.map((result) => (
                        <Link key={result._id} to={`/contents/${result._id}`}>
                            <Card>
                                <CardTitle text={result.title} />
                                { result.subject && <CardSubtitle text={`#${(result.subject as Subject).name}`} />}
                                { !result.subject && <CardSubtitle text="legacy" />}
                            </Card>
                        </Link>
                    ))}
                </DisplayGrid>
            )}
        </VStack>
    );
};

export default SearchResults;
