import React from "react";
import { Flex, Hide, Image, Show, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { userState } from "../../../store/slices/user";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import SearchContent from "../../Contents/components/SearchContent";

const Branding = () => (
    <Link to="/">
        <Flex alignItems="center">
            <Image src="/logo192.png" w="50px" h="auto" />
            <Text textColor="green.500" fontSize="2xl">
                Cordia
            </Text>
        </Flex>
    </Link>
);

const TopBar = () => {
    const { user } = useSelector(userState);

    return (
        <>
            <Flex
                minWidth="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Branding />
                {user && (
                    <>
                        <Show above="md">
                            <SearchContent />
                        </Show>
                        <UserMenu user={user!} />
                    </>
                )}
            </Flex>
            <Hide above="md">
                <SearchContent />
            </Hide>
        </>
    );
};

export default TopBar;
