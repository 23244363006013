import React from "react";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import { CardHeight, CardWidth, Subject } from "../../../common/types";
import { Link } from "react-router-dom";
import EditSubject from "../EditSubject";

const SubjectCard = ({ subject }: { subject: Subject }) => {
    const {isOpen, onClose, onOpen} = useDisclosure();
    return (
        <>
        
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            w={CardWidth}
            h={CardHeight}
            borderWidth="1px"
            borderRadius="lg"
            p="2"
        >
            <EditSubject id={subject._id} isOpen={isOpen} onClose={onClose} />
            <Link to={`/subjects/${subject._id}/contents`}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text
                        fontSize="xl"
                        textColor="CaptionText"
                        fontWeight="semibold"
                    >
                        {subject.name}
                    </Text>
                    <Text
                        fontSize="md"
                        textColor="blackAlpha.600"
                        noOfLines={1}
                    >
                        {subject.motivation}
                    </Text>
                </Box>
            </Link>
            <Box>
                <Button variant="link" onClick={onOpen}>Edit</Button>
            </Box>
        </Box>
        </>
    );
};

export default SubjectCard;
