import { SimpleGrid } from "@chakra-ui/react";
import React from "react";

const DisplayGrid = ({ children }: { children: React.ReactNode }) => (
    <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={4}
        marginBottom={10}
        width="100%"
    >
        {children}
    </SimpleGrid>
);

export default DisplayGrid;
