import React, { useEffect, useState } from "react";
import { Button, Stack, useMediaQuery } from "@chakra-ui/react";
import FavoriteButton from "../../Content/components/FavoriteButton";
import RelatedContentButton from "../../Content/components/RelatedContentButton";
import KnowledgeCardButton from "../../Content/components/KnowledgeCardButton";
import DeleteButton from "../../Content/components/DeleteButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

interface OwnProps {
    onLinkContent: () => void;
}

export const ContentActionsButtons = ({ onLinkContent }: OwnProps) => {
    const [isAtLeastMedium] = useMediaQuery("(min-width: 620px)");
    const [isShown, setIsShown] = useState(true);

    useEffect(() => {
        setIsShown(isAtLeastMedium);
    }, [isAtLeastMedium]);

    return (
        <>
            {!isShown && (
                <Button
                    variant="ghost"
                    onClick={() => {
                        setIsShown(true);
                    }}
                    leftIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                    Content Actions
                </Button>
            )}
            {isShown && (
                <>
                    {!isAtLeastMedium && (
                        <Button
                            variant="ghost"
                            onClick={() => {
                                setIsShown(false);
                            }}
                            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                        >
                            Content Actions
                        </Button>
                    )}
                    <Stack
                        direction={isAtLeastMedium ? "row" : "column"}
                        marginY={4}
                        spacing={1}
                    >
                        <FavoriteButton />
                        <RelatedContentButton onLinkContent={onLinkContent} />
                        <KnowledgeCardButton />
                        <DeleteButton />
                    </Stack>
                </>
            )}
        </>
    );
};
