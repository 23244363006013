import React, { useContext } from "react";
import { Button } from "@chakra-ui/react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentContext from "../../../common/context/ContentContext";
import { useDispatch, useSelector } from "react-redux";
import { contentActions, contentState } from "../../../store/slices/content";

const FavoriteButton = () => {
    const content = useContext(ContentContext);
    const dispatch = useDispatch();
    const { isSaving } = useSelector(contentState);

    const onToggleFavorite = (isFavorite: boolean) => {
        if (content) {
            dispatch(
                contentActions.updateContent({
                    id: content._id,
                    content: { isFavorite },
                })
            );
        }
    };

    return (
        <>
            {!content ||
                (!content.isFavorite && (
                    <Button
                        colorScheme="yellow"
                        variant="outline"
                        leftIcon={<FontAwesomeIcon icon={faStar} />}
                        onClick={() => { onToggleFavorite(true); }}
                        isLoading={isSaving}
                    >
                        Favorite
                    </Button>
                ))}
            {content?.isFavorite && (
                <Button
                    colorScheme="yellow"
                    variant="outline"
                    leftIcon={<FontAwesomeIcon icon={faStar} />}
                    onClick={() => { onToggleFavorite(false); }}
                    isLoading={isSaving}
                >
                    Unfavorite
                </Button>
            )}
        </>
    );
};

export default FavoriteButton;
