import React, { useRef } from "react";
import {
    Box,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../../common/types";
import { useNavigate } from "react-router-dom";

const SearchContent = () => {
    const navigate = useNavigate();
    const searchRef = useRef("");
    const inputRef = useRef(null);

    const goToSearch = () => {
        navigate(
            {
                pathname: "/contents",
                search: `?s=${searchRef.current}`,
            },
            {
                replace: true,
            }
        );
        (inputRef.current! as HTMLInputElement).value = "";
    };
    return (
        <Box width={{ base: "90%", sm: "60%" }}>
            <InputGroup>
                <Input
                    ref={inputRef}
                    focusBorderColor={Colors.defaultGreen}
                    name="search"
                    type="search"
                    placeholder="Search content"
                    size="lg"
                    onChange={(e) => {
                        searchRef.current = e.target.value;
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            goToSearch();
                        }
                    }}
                />
                <InputRightElement
                    top="4px"
                    children={
                        <IconButton
                            size="lg"
                            colorScheme="green"
                            aria-label="Search content"
                            icon={<FontAwesomeIcon icon={faSearch} />}
                            onClick={() => {
                                goToSearch();
                            }}
                        />
                    }
                />
            </InputGroup>
        </Box>
    );
};

export default SearchContent;
