import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CreateContentPayload } from "../../api/content";
import { Content } from "../../common/types";
import { UpdateContent } from "../types";

interface OwnState {
    activeContent: null | Content;
    hasErrorSaving: boolean;
    hasErrorLoading: boolean;
    isSaving: boolean;
    isLoading: boolean;
    content: Content[];
};

const initialState: OwnState = {
    activeContent: null,
    hasErrorSaving: false,
    isSaving: false,
    isLoading: false,
    hasErrorLoading: false,
    content: [],
};

const slice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        deleteContentCompleted: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.hasErrorLoading = false;
            state.content = [...state.content].filter(({_id}) => _id !== action.payload);
        },
        getContent: (state, _: PayloadAction<string>) => {
            state.isLoading = true;
            state.hasErrorLoading = false;
        },
        getContentCompleted: (state, action: PayloadAction<Content>) => {
            state.isLoading = false;
            state.activeContent = action.payload;
            state.hasErrorLoading = false;
        },
        getContentFailed: (state, _: PayloadAction<string>) => {
            state.isLoading = false;
            state.hasErrorLoading = true;
            state.activeContent = null;
        },
        loadContent: (state, _: PayloadAction<string>) => {
            state.isLoading = true;
            state.hasErrorLoading = false;
        },
        loadContentCompleted: (state, action: PayloadAction<Content[]>) => {
            state.isLoading = false;
            state.hasErrorLoading = false;
            state.content = action.payload;
        },
        loadContentFailed: (state) => {
            state.isLoading = false;
            state.hasErrorLoading = true;
        },
        postContent: (state, _: PayloadAction<CreateContentPayload>) => {
            state.isSaving = true;
            state.hasErrorSaving = false;
        },
        postContentCompleted: (state, action: PayloadAction<Content>) => {
            state.isSaving = false;
            state.hasErrorSaving = false;
            state.content.push(action.payload);
        },
        postContentFailed: (state) => {
            state.isSaving = false;
            state.hasErrorSaving = true;
        },
        updateContent: (state, _: PayloadAction<UpdateContent>) => {
            state.isSaving = true;
            state.hasErrorSaving = false;
        },
        updateContentCompleted: (state, action: PayloadAction<Content>) => {
            state.isSaving = false;
            state.hasErrorSaving = false;
            const updatedContent = [...state.content].filter(content => content._id !== action.payload._id);
            updatedContent.push(action.payload);
            state.content = updatedContent;
            state.activeContent = action.payload;
        },
        updateContentFailed: (state) => {
            state.isSaving = false;
            state.hasErrorSaving = true;
        },
        updateActiveContent: (state, action: PayloadAction<Content>) => {
            state.activeContent = action.payload;
        },
    },
});

export const contentActions = slice.actions;
export const contentState = (state: RootState) => state.content;

export default slice.reducer;