import React, { useEffect, useState } from "react";
import { Box, Spacer, Stack, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { H1 } from "../../common/components/Texts";
import { subjectActions, subjectState } from "../../store/slices/subject";
import { Subject } from "../../common/types";
import { userState } from "../../store/slices/user";
import CreateContentMenu from "./components/CreateContentMenu";
import ContentGrid from "./components/ContentGrid";
import RecapKnowledgeButton from "./components/RecapKnowledgeButton";
import ContentMapButton from "./components/ContentMapButton";

const SubjectContents = ({ subject }: { subject: Subject }) => {
    return (
        <VStack width="100%">
            <H1 text={subject.name} />
            <Stack direction={["column", "row"]} alignItems="center">
                <CreateContentMenu subjectId={subject._id} />
                <RecapKnowledgeButton subjectId={subject._id} />
                <ContentMapButton subjectId={subject._id} />
            </Stack>
            <Spacer />
            <Box width="100%">
                <ContentGrid subjectId={subject._id} loadFavorites={true} />
                <ContentGrid subjectId={subject._id} />
            </Box>
        </VStack>
    );
};

const Contents = () => {
    const { id: subjectId } = useParams();
    const { subjects } = useSelector(subjectState);
    const { user } = useSelector(userState);
    const [subject, setSubject] = useState<Subject | undefined>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (subjects.length === 0) {
            dispatch(subjectActions.loadSubjects(user!._id));
        }
    }, [subjectId]);

    useEffect(() => {
        if (subjects.length > 0) {
            setSubject(subjects.find((s) => s._id === subjectId));
        }
    }, [subjects]);

    return <>{subject && <SubjectContents subject={subject} />}</>;
};

export default Contents;
