import React from "react";
import { Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export const ExternalLink = ({ url }: { url: string }) => (
    <Link href={url} target="_blank" fontSize="md" color="blue.600" isExternal>
        {url + " "}
        <FontAwesomeIcon icon={faExternalLink} />
    </Link>
);

export const InternalLink = ({
    url,
    text,
    size,
}: {
    url: string;
    text: string;
    size?: string;
}) => (
    <Link href={url}>
        <Text color="blue.600" fontSize={size ? size : "medium"}>
            {text}
        </Text>
    </Link>
);
