import React, { useContext } from "react";
import {
    Button,
    ButtonGroup,
    HStack,
    Spacer,
    Stack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { H3 } from "../../../common/components/Texts";
import ContentContext from "../../../common/context/ContentContext";
import { useNavigate } from "react-router-dom";

interface OwnProps {
    title: string | React.ReactNode;
    onSave: () => void;
    isSaving: boolean;
    onCloseWithAutosave?: () => void;
};


const SaveButton = ({isSaving, onSave}: Pick<OwnProps, 'isSaving' | 'onSave'>) => {
    return <Button
    colorScheme="green"
    variant="outline"
    leftIcon={<FontAwesomeIcon icon={faCloudArrowUp} />}
    isLoading={isSaving}
    onClick={onSave}
    fontSize="large"
>
    Save
</Button>;
}

const Header = ({
    title,
    isSaving,
    onSave,
    onCloseWithAutosave,
}: OwnProps) => {
    const content = useContext(ContentContext);
    const navigate = useNavigate();
    return (
        <>
            <Stack
            direction={['column', 'row']}
                alignItems="center"
                justifyContent="space-between"
                marginRight={6}
            >
                { typeof title === "string" && <H3 text={title} /> }
                { typeof title !== "string" && title }
                <Spacer />
                <HStack>

                    <ButtonGroup spacing={2}>
                        <SaveButton isSaving={isSaving} onSave={onSave} />
                        <Spacer />
                        {content && content.subject && (
                            <Button
                                onClick={() => {
                                    onCloseWithAutosave
                                        ? onCloseWithAutosave()
                                        : navigate(
                                              `/subjects/${content.subject}/contents`
                                          );
                                }}
                                variant="outline"
                                colorScheme="red"
                                title="Close"
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </Button>
                        )}
                    </ButtonGroup>
                </HStack>
            </Stack>
        </>
    );
};

export default Header;
