
export const debounce = (callback: Function, duration: number) => {
    let timer: NodeJS.Timeout | undefined;

    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout((...args) => {
        timer = undefined;
        callback(...args);
    }, duration);
};