import React, { useContext } from "react";
import { Button, Text, useDisclosure } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots as leftIcon } from "@fortawesome/free-solid-svg-icons";
import ContentContext from "../../../common/context/ContentContext";
import ContentActionsContext from "../../../common/context/ContentActionsContext";


const KnowledgeCardButton = () => {
    const content = useContext(ContentContext);
    const contentActions = useContext(ContentActionsContext);

    return (
        <>
            {content && contentActions?.onViewKnowledge && (
                <>
                    <Button
                        colorScheme="purple"
                        variant="outline"
                        leftIcon={<FontAwesomeIcon icon={leftIcon} />}
                        onClick={contentActions.onViewKnowledge}
                    >
                        Knowledge Cards
                    </Button>
                </>
            )}
        </>
    );
};

export default KnowledgeCardButton;
