import React, { useEffect, useRef, useState } from "react";
import { Box, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { H2Error } from "../../common/components/Texts";
import { Content, Subject } from "../../common/types";
import Header from "../Contents/components/ContentHeader";
import { useDispatch, useSelector } from "react-redux";
import { contentActions, contentState } from "../../store/slices/content";
import ContentForm from "./components/ContentForm";
import PopMenu from "../../common/components/PopMenu";
import ContentLinker from "./components/ContentLinker";
import ContentContext from "../../common/context/ContentContext";
import ContentKnowledgeViewer from "./components/ContentKnowledgeViewer";
import ContentActionsContext from "../../common/context/ContentActionsContext";
import { InternalLink } from "../../common/components/Links";
import useLazyRequest from "../../common/hooks/useLazyRequestWithoutState";

const ContentView = () => {
    const { id } = useParams();
    const [content, setContent] = useState<undefined | Content>();
    const {
        isSaving,
        hasErrorSaving,
        activeContent,
        isLoading,
        hasErrorLoading,
    } = useSelector(contentState);
    const [subject, setSubject] = useState<Pick<Subject, "_id" | "name">>({
        _id: "",
        name: "",
    });
    const [hasSaved, setHasSaved] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const {
        isOpen: isLinkerOpen,
        onClose: onCloseLinker,
        onOpen: onOpenLinker,
    } = useDisclosure();
    const {
        isOpen: isKnowledgeOpen,
        onClose: onCloseKnowledge,
        onOpen: onOpenKnowledge,
    } = useDisclosure();
    const titleRef = useRef("");
    const contentRef = useRef("");
    const keyConceptsRef = useRef("");
    const openQuestionsRef = useRef("");
    const isClosingDirtyRef = useRef(false);
    const isAllSavedRef = useRef(true);
    const navigate = useNavigate();
    
    const errorToast = () =>
        toast({
            description:
                "We've encountered an error saving your content. Please try again",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
        });
    const successToast = () =>
        toast({
            description: "Content successfully saved.",
            status: "success",
            variant: "subtle",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "small",
        });
    
    const patchContent = useLazyRequest<Content>("PATCH", `/content/${id}`, {
        onSuccess: () => {
            successToast();
            isAllSavedRef.current = true;
        },
        onError: (err) => {
            console.error("Something went wrong patching content", err);
            errorToast();
        },
    });

    const onSave = () => {
        if (content) {
            setHasSaved(true);
            dispatch(
                contentActions.updateContent({
                    id: content!._id,
                    content: {
                        title: titleRef.current,
                        content: contentRef.current,
                        keyConcepts: keyConceptsRef.current,
                        openQuestions: openQuestionsRef.current,
                    },
                })
            );
        }
    };

    useEffect(() => {
        dispatch(contentActions.getContent(id!));
    }, []);

    useEffect(() => {
        if (activeContent && activeContent._id === id) {
            setContent({ ...activeContent });
            titleRef.current = activeContent.title;
            contentRef.current = activeContent.content;
            keyConceptsRef.current = activeContent.keyConcepts || "";
            openQuestionsRef.current = activeContent.openQuestions || "";
            if (isClosingDirtyRef.current) {
                navigate(`/subjects/${activeContent.subject}/contents`);
            }
            if (activeContent.subjectInfo) {
                setSubject({
                    _id: activeContent.subjectInfo._id,
                    name: activeContent.subjectInfo.name,
                });
            }
        }
    }, [activeContent]);

    useEffect(() => {
        if (hasSaved && !isSaving) {
            setHasSaved(false);
            if (hasErrorSaving) {
                errorToast();
            } else {
                successToast();
            }
        }
    }, [isSaving, hasErrorSaving, hasSaved]);

    const onClose = () => {
        navigate(`/subjects/${activeContent!.subject}/contents`);
    };

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && hasErrorLoading && (
                <H2Error text="Could not load content" />
            )}
            {!isLoading && !hasErrorLoading && content && (
                <ContentContext.Provider value={content}>
                    <ContentActionsContext.Provider
                        value={{ onViewKnowledge: onOpenKnowledge }}
                    >
                        <Box w="100%">
                            <Header
                                title={
                                    subject && subject._id !== "" ? (
                                        <InternalLink
                                            url={`/subjects/${subject._id}/contents`}
                                            text={subject.name}
                                            size="2xl"
                                        />
                                    ) : (
                                        "Edit Content"
                                    )
                                }
                                isSaving={isSaving}
                                onSave={onSave}
                                onCloseWithAutosave={onClose}
                            />
                            <PopMenu
                                header="Related Content"
                                isOpen={isLinkerOpen}
                                onClose={onCloseLinker}
                            >
                                <ContentLinker
                                    id={content._id}
                                    defaultLinked={content.relationships}
                                />
                            </PopMenu>
                            <PopMenu
                                header="Test your knowledge on the content"
                                isOpen={isKnowledgeOpen}
                                onClose={onCloseKnowledge}
                            >
                                <ContentKnowledgeViewer />
                            </PopMenu>
                            <ContentForm
                                titleRef={titleRef}
                                contentRef={contentRef}
                                keyConceptsRef={keyConceptsRef}
                                openQuestionsRef={openQuestionsRef}
                                onLinkContent={onOpenLinker}
                                patchContent={patchContent}
                                isAllSavedRef={isAllSavedRef}
                            />
                        </Box>
                    </ContentActionsContext.Provider>
                </ContentContext.Provider>
            )}
        </>
    );
};

export default ContentView;
