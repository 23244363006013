import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, useDisclosure, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileCirclePlus,
    faLayerGroup,
    faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import InputDialog from "../../../common/components/InputDialog";
import { contentActions, contentState } from "../../../store/slices/content";
import Header from "./ContentHeader";
import NoteContentForm, { NoteDataInput } from "./NoteContentForm";
import { ContentType } from "../../../common/types";
import WebContentForm, { WebDataInput } from "./WebContentForm";

const ContentInputDialog = ({
    saveContent,
    isOpen,
    onClose,
    inputForm,
}: {
    isOpen: boolean;
    onClose: () => void;
    saveContent: () => void;
    inputForm: React.ReactElement;
}) => {
    const { isSaving, hasErrorSaving } = useSelector(contentState);
    const [hasSaved, setHasSaved] = useState(false);
    const toast = useToast();

    const onSave = () => {
        setHasSaved(true);
        saveContent();
    };

    useEffect(() => {
        if (hasSaved && !isSaving) {
            setHasSaved(false);
            if (hasErrorSaving) {
                toast({
                    title: "Cannot save content.",
                    description:
                        "We've encountered an error saving your content. Please try again",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Content saved.",
                    description: "We've successfully saved your content.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        }
    }, [isSaving, hasErrorSaving, hasSaved]);

    return (
        <InputDialog
            body={inputForm}
            isOpen={isOpen}
            onClose={onClose}
            header={
                <Header title="Content" onSave={onSave} isSaving={isSaving} />
            }
            isFullSize={true}
        />
    );
};

const CreateContentMenu = ({ subjectId }: { subjectId: string }) => {
    const noteDataRef = useRef<NoteDataInput>({ title: "", content: "" });
    const webDataRef = useRef<WebDataInput>({
        title: "",
        content: "",
        url: "",
    });
    const [contentType, setContentType] = useState<ContentType>("text");
    const [inputForm, setInputForm] = useState<React.ReactElement>(
        <NoteContentForm dataRef={noteDataRef} />
    );
    const { onOpen, isOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();

    const saveContent = () => {
        if (contentType === "text") {
            dispatch(
                contentActions.postContent({
                    ...noteDataRef.current,
                    contentType,
                    subject: subjectId,
                })
            );
        } else if (contentType === "website") {
            dispatch(
                contentActions.postContent({
                    ...webDataRef.current,
                    contentType,
                    subject: subjectId,
                })
            );
        }
    };

    return (
        <>
            <ContentInputDialog
                isOpen={isOpen}
                onClose={onClose}
                saveContent={saveContent}
                inputForm={inputForm}
            />
            <ButtonGroup variant="outline" spacing="3">
                <Button
                    colorScheme="green"
                    leftIcon={<FontAwesomeIcon icon={faLayerGroup} />}
                    onClick={() => {
                        setInputForm(
                            <NoteContentForm
                                dataRef={noteDataRef}
                            />
                        );
                        setContentType("text");
                        onOpen();
                    }}
                >
                    New Note
                </Button>
                <Button
                    colorScheme="green"
                    leftIcon={<FontAwesomeIcon icon={faBookmark} />}
                    onClick={() => {
                        setInputForm(
                            <WebContentForm dataRef={webDataRef} />
                        );
                        setContentType("website");
                        onOpen();
                    }}
                >
                    New Bookmark
                </Button>
            </ButtonGroup>
        </>
    );
};

export default CreateContentMenu;
