import { useState, useEffect } from "react";
import { Method, REACT_APP_API_URL } from "../../api/base";
import { RequestState } from "./types";

export default function useRequest<T>(method: Method, relativeUrl: string, payload?: object) {
    const [status, setStatus] = useState<RequestState<T>>({
        isLoading: false,
        hasError: false,
        data: null,
    });

    useEffect(() => {
        setStatus({ ...status, isLoading: true });

        fetch(`${REACT_APP_API_URL}${relativeUrl}`,
            {
                method,
                credentials: 'include',
                body: payload ? JSON.stringify(payload) : undefined,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
            .then(res => res.json())
            .then(data => {
                setStatus({
                    isLoading: false,
                    hasError: false,
                    data: data,
                });
            })
            .catch(err => {
                console.error("Something went wrong while requesting", relativeUrl, ":", err.message);
                setStatus({
                    isLoading: false,
                    hasError: true,
                    data: null,
                });
            });
    }, []);

    return status;
}