import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    Checkbox,
    CheckboxGroup,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Textarea,
} from "@chakra-ui/react";
import InputDialog from "../../../common/components/InputDialog";
import { Subject, WEEK_DAYS } from "../../../common/types";
import { CreateSubject } from "../../../api/subject";

interface OwnProps {
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: () => void;
    onSave: (data: Omit<CreateSubject, "user">) => void;
    defaultData?: Subject;
    onDelete?: () => void;
}

const SubjectForm = ({
    onSave,
    isSubmitting,
    defaultData,
    onDelete,
}: {
    onSave: (data: Omit<CreateSubject, "user">) => void;
    isSubmitting: boolean;
    defaultData?: Subject;
    onDelete?: () => void;
}) => {
    const [data, setData] = useState<Omit<CreateSubject, "user">>({
        name: defaultData?.name || "",
        motivation: defaultData?.motivation || "",
        featured: defaultData?.featured || [],
    });
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    return (
        <form>
            <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                    type="text"
                    placeholder="Awesome and interesting stuff"
                    onChange={(e) => {
                        setData({
                            ...data,
                            name: e.target.value,
                        });
                    }}
                    required
                    defaultValue={defaultData?.name}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Why do you want to learn this? (optional)</FormLabel>
                <Textarea
                    onChange={(e) => {
                        setData({
                            ...data,
                            motivation: e.target.value,
                        });
                    }}
                    defaultValue={defaultData?.motivation}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Featured on the following days (optional)</FormLabel>
                <CheckboxGroup
                    colorScheme="green"
                    defaultValue={
                        defaultData
                            ? defaultData.featured.map((d) => d.toString())
                            : []
                    }
                >
                    <Stack spacing={[1, 5]} direction="row">
                        {Object.entries(WEEK_DAYS).map(([dow, text]) => (
                            <Checkbox
                                key={dow}
                                value={dow}
                                onChange={(e) => {
                                    let featured = [...data.featured];
                                    if (e.target.checked) {
                                        featured.push(dow as unknown as number);
                                    } else {
                                        featured = featured.filter(
                                            (d) =>
                                                d.toString() !== dow.toString()
                                        );
                                    }
                                    setData({
                                        ...data,
                                        featured,
                                    });
                                }}
                            >
                                {text}
                            </Checkbox>
                        ))}
                    </Stack>
                </CheckboxGroup>
            </FormControl>
            <FormControl mt="10px" display="flex" justifyContent="end">
                <ButtonGroup spacing={4}>
                    {!showConfirmDelete && (
                        <>
                            {defaultData && defaultData._id && (
                                <Button
                                    size="lg"
                                    colorScheme="red"
                                    onClick={() => {
                                        setShowConfirmDelete(true);
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                            <Button
                                size="lg"
                                colorScheme={"green"}
                                onClick={() => {
                                    onSave(data);
                                }}
                                isLoading={isSubmitting}
                            >
                                Save
                            </Button>
                        </>
                    )}
                    {showConfirmDelete && (
                        <>
                            <Button
                                size="lg"
                                colorScheme="red"
                                onClick={() => {
                                    if (onDelete) {
                                        onDelete();
                                    }
                                    
                                }}
                            >
                                Yes, delete {defaultData && defaultData.name}
                            </Button>

                            <Button
                                size="lg"
                                colorScheme="blackAlpha"
                                variant="outline"
                                onClick={() => {
                                    setShowConfirmDelete(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </ButtonGroup>
            </FormControl>
        </form>
    );
};

const InputSubject = ({
    isOpen,
    isSubmitting,
    onClose,
    onSave,
    defaultData,
    onDelete
}: OwnProps) => (
    <InputDialog
        isOpen={isOpen}
        onClose={onClose}
        body={
            <SubjectForm
                onSave={onSave}
                isSubmitting={isSubmitting}
                defaultData={defaultData}
                onDelete={onDelete}
            />
        }
        header={
            defaultData && defaultData._id ? (
                <>Edit Subject</>
            ) : (
                <>Create Subject</>
            )
        }
    />
);

export default InputSubject;
