import { Content, ContentType } from "../common/types";
import callApi from "./base";

export interface CreateContentPayload {
    contentType: ContentType;
    subject: string;
    content?: string;
    title?: string;
    url?: string;
    isFavorite?: boolean;
    keyConcepts?: string;
    openQuestions?: string;
}

export const getContentById = (id: string): Promise<Content> => callApi("GET", `/content/${id}`);

export const getContentBySubject = (subjectId: string): Promise<Content[]> => callApi("GET", `/content?mode=subject&subject=${subjectId}`);

export const postContentForSubject = (content: CreateContentPayload): Promise<Content> => callApi("POST", `/content`, { content });

export const updateContent = (id: string, content: Partial<CreateContentPayload>): Promise<Content> => callApi("PATCH", `/content/${id}`, { ...content });
