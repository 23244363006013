import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLeading } from "redux-saga/effects";
import { LoginResponse, getMe, postLogin, postLogout } from "../../api/user";
import { User } from "../../common/types";
import { userActions } from "../slices/user";
import { LoginPayload } from "../types";

function* callLogin(action: PayloadAction<LoginPayload>) {
    try {
        const response: LoginResponse = yield call(postLogin, action.payload);
        yield put(userActions.loginCompleted({...response}));
    } catch (err) {
        yield put(userActions.loginFailed());
    }
}

function* callMe() {
    try {
        const user: User = yield call(getMe);
        yield put(userActions.loadMeCompleted(user));
    } catch (err) {
        yield put(userActions.loadMeFailed());
    }
}

function* callLogout() {
    try {
        yield call(postLogout);
        yield put(userActions.logoutSuccess());
    } catch (err) {
        yield put(userActions.logoutSuccess());
    }
}

export default function* userSaga() {
    yield takeLeading(userActions.login.type, callLogin);
    yield takeLeading(userActions.loadMe.type, callMe);
    yield takeLeading(userActions.logout.type, callLogout);
}