import React, { useState } from "react";
import {
    Box,
    Center,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Image,
    VStack,
    Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userState } from "../../store/slices/user";
import { ErrorText } from "../../common/components/Texts";

const Login = () => {
    const dispatch = useDispatch();
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const { isLoginIn, isLoadingMe, hasError } = useSelector(userState);

    return (
        <>
            {!isLoadingMe && (
                <Center h="80%" p="3">
                    <VStack>
                        <Box>
                            <Heading color="green.500">
                                <Image src="/logo192.png" />
                                <Center>Cordia</Center>
                            </Heading>
                        </Box>
                        <Box
                            w={{ base: "380px", md: "600px" }}
                            boxShadow="md"
                            rounded="md"
                            p="8"
                        >
                            <Heading>Login</Heading>
                            <form>
                                <FormControl>
                                    <FormLabel>Email address</FormLabel>
                                    <Input
                                        type="email"
                                        onChange={(e) => {
                                            setCredentials({
                                                ...credentials,
                                                email: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Password</FormLabel>
                                    <Input
                                        type="password"
                                        onChange={(e) => {
                                            setCredentials({
                                                ...credentials,
                                                password: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                                <FormControl marginTop="10px">
                                    <Button
                                        w="100%"
                                        colorScheme="green"
                                        isLoading={isLoginIn}
                                        onClick={() => {
                                            dispatch(
                                                userActions.login({
                                                    ...credentials,
                                                })
                                            );
                                        }}
                                    >
                                        Login
                                    </Button>
                                </FormControl>
                            </form>
                            { hasError && <ErrorText text="Cannot login. Please verify your credentials" /> }
                        </Box>
                    </VStack>
                </Center>
            )}
        </>
    );
};

export default Login;
