import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useLazyRequest from "../../common/hooks/useLazyRequest";
import { Spinner, Text, VStack } from "@chakra-ui/react";
import { Content, Subject } from "../../common/types";
import { H1 } from "../../common/components/Texts";
import ContentNodesMap from "./components/ContentNodesMap";
import { InternalLink } from "../../common/components/Links";

interface MapResponse {
    subject: Subject;
    content: Pick<Content, "_id" | "title" | "relationships">[];
}
const ContentMap = () => {
    const { id: subjectId } = useParams();
    const [nodes, setNodes] = useState<any[]>([]);
    const [edges, setEdges] = useState<any[]>([]);
    const [{ data, hasError, isLoading }, callGetMap] =
        useLazyRequest<MapResponse>("GET", `/subject/${subjectId}/map`);

    useEffect(() => {
        callGetMap();
    }, [subjectId]);

    useEffect(() => {
        if (data && data.content) {
            const initialNodes = data.content.map((content, index) => {
                return {
                    id: content._id,
                    data: { label: content.title },
                    position: {
                        x: (index % 4) * 300,
                        y: 20 + Math.floor(index / 4) * 120,
                    },
                    style: {
                        background: '#63B3ED',
                        color: 'white',
                        width: 180,
                      },
                };
            });

            const initialEdges: any[] = [];

            data.content
                .filter((content) => content.relationships.length > 0)
                .forEach((content) => {
                    content.relationships.forEach(({ otherContent }) => {
                        const id =
                            content._id > otherContent._id
                                ? content._id + otherContent._id
                                : otherContent._id + content._id;
                        if (!initialEdges.find((e) => e.id === id)) {
                            initialEdges.push({
                                id,
                                source: content._id,
                                target: otherContent._id,
                            });
                        }
                    });
                });
            console.log("Pushing");
            setNodes([...initialNodes]);
            setEdges([...initialEdges]);
        }
    }, [data]);

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && hasError && <></>}
            {!isLoading && !hasError && data && (
                <VStack spacing={4}>
                <InternalLink url={`/subjects/${subjectId}/contents`} text={data.subject.name} size="3xl" />
                <Text fontSize="2xl">Content Map</Text>
                <Text alignSelf="flex-end" fontStyle="italic">Use double-click to navigate to content</Text>
                    {nodes.length > 0 && (
                        <div style={{ width: 1200, height: 1200 }}>
                            <ContentNodesMap nodes={nodes} edges={edges} />
                        </div>
                    )}
                </VStack>
            )}
        </>
    );
};

export default ContentMap;
