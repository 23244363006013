import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Center, SimpleGrid, Spinner } from "@chakra-ui/react";
import { Content } from "../../../common/types";
import { ContentCard } from "./ContentCard";
import { ErrorText, H3Gray } from "../../../common/components/Texts";
import useDynamicRequest from "../../../common/hooks/useDynamicRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { contentState } from "../../../store/slices/content";

interface OwnProps {
    subjectId: string;
    loadFavorites?: boolean;
}

const ContentGrid = ({ subjectId, loadFavorites }: OwnProps) => {
    const limit = 20;
    const skip = useRef(0);
    const searchParams = new URLSearchParams({
        subject: subjectId,
        skip: "0",
        limit: limit.toString(),
    });
    if (loadFavorites) {
        searchParams.append("favorites", "true");
    }

    const [{ data, hasError, isLoading }, callGetContent] =
        useDynamicRequest<Content[]>();
    const [content, setContent] = useState<Content[]>([]);
    const { content: stateContent } = useSelector(contentState);

    useEffect(() => {
        callGetContent(
            "GET",
            `/content?mode=subject&${searchParams.toString()}`
        );
    }, []);

    const updateContent = (batch: Content[]) => {
        const notAlreadyInContent = batch.filter(
            (cd) => !content.find((c) => c._id === cd._id) && cd.isFavorite === !!loadFavorites
        );
        setContent([...notAlreadyInContent, ...content]);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            updateContent(data);
        }
    }, [data]);

    useEffect(() => {
        if (stateContent.length > 0) {
            updateContent(stateContent);
        }
    }, [stateContent]);

    const onLoadMoreContent = () => {
        const currentSkip = (skip.current + 1) * limit;
        searchParams.set("skip", currentSkip.toString());

        callGetContent(
            "GET",
            `/content?mode=subject&${searchParams.toString()}`
        );
        skip.current += 1;
    };

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && hasError && (
                <ErrorText text="Something went wrong loading the content, please try again." />
            )}
            {!content.length && !loadFavorites && (
                <H3Gray text="No content yet, create some 👆🏽" />
            )}
            {content.length > 0 && (
                <Box marginBottom={20}>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 2,
                            lg: content.length >= 3 ? 4 : 2,
                        }}
                        spacing={4}
                        placeContent="center"
                    >
                        {content.map((c) => (
                            <ContentCard key={c._id} content={c} />
                        ))}
                    </SimpleGrid>
                    {data && data.length === limit && (
                        <Center>
                            <Button
                                variant="ghost"
                                colorScheme="blackAlpha"
                                rightIcon={
                                    <FontAwesomeIcon icon={faSortDown} />
                                }
                                onClick={onLoadMoreContent}
                            >
                                { loadFavorites ? "Load More Favorite Content" : "Load More Content" }
                            </Button>
                        </Center>
                    )}
                </Box>
            )}
        </>
    );
};

export default ContentGrid;
