import { Subject } from "../common/types";
import callApi from "./base";

export interface CreateSubject {
    user: string;
    name: string;
    motivation: string;
    featured: number[];
}

export interface UpdateSubject {
    id: string;
    payload: Partial<CreateSubject>;
}

export const deleteSubject = (id: string): Promise<void> => callApi("DELETE", `/subject/${id}`);

export const getSubjects = (userId: string): Promise<[Subject]> => callApi("GET", `/subject?user=${userId}`);

export const patchSubject = (data: UpdateSubject) => callApi("PATCH", `/subject/${data.id}`, data.payload);

export const postSubject = (payload: CreateSubject) => callApi("POST", "/subject", payload);