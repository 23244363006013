import React from "react";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const RecapKnowledgeButton = ({subjectId}: {subjectId: string}) => {
    return (
        <Link to={`/knowledge/recap/${subjectId}`}>
        <Button variant="outline" colorScheme="purple" leftIcon={<FontAwesomeIcon icon={faBrain} />}>
            Recap your knowledge
        </Button>
        </Link>
    );
};

export default RecapKnowledgeButton;
