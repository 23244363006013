/**
 * Common types, const and interfaces
 */

import { Relationship } from "cordia-common/lib/types";

 interface TimestampRecord {
    _id: string;
    createdAt: string;
    updatedAt?: string;
}

/** Types **/
export type RequestStatus = 'initial' | 'pending' | 'resolved' | 'rejected';
export type ContentType = 'text' | 'website';
export type MessageType = 'success' | 'alert' | 'warning' | 'info';
export type SearchResult = Pick<Content, "_id" | "title" | "subject">;

/** Interfaces **/
export interface AsyncState {
    status: RequestStatus;
};

export interface Content extends TimestampRecord {
    contentType: ContentType;
    content: string;
    title: string;
    topics: Topic[];
    isFavorite: boolean;
    subject: Subject | string;
    relationships: ContentRelationship[];
    keyConcepts?: string;
    openQuestions?: string;
    url?: string;
    subjectInfo?: Pick<Subject, '_id' | 'name'>;
};

export interface ContentKnowledge extends TimestampRecord {
    answer: string;
    content: string | Content;
    question: string;
    user: string | User;
};

interface ContentRelationship {
    _id: string;
    relationship: Relationship;
    otherContent: Pick<Content, '_id' | 'title' | 'subject'>;
}

export interface WebsiteContent extends Content {
    contentType: "website",
    url: string;
};


export interface User extends TimestampRecord {
    email: string;
    name: string;
}

export interface Subject extends TimestampRecord {
    name: string;
    content: Content[];
    featured: number[];
    motivation?: string;
}

export interface Topic extends TimestampRecord {
    name: string;
    description: string;
    content?: Content[];
    contentType?: string;
    contentCount?: number;
}

export interface GetContentParameters {
    mode?: string;
    topicId?: string;
    search?: string;
}

export interface Link {
    url: string;
    title: string;
}

/** CONSTs **/

export const BROADCAST_CHANNEL = 'CORDIA_BC';
export const WEEK_DAYS: { [key: number]: string } = {
    0: "Su",
    1: "Mo",
    2: "Tu",
    3: "We",
    4: "Th",
    5: "Fr",
    6: "Sa",
};

export const CardWidth = { base: "100%" };
export const CardHeight = "120px";

export const Colors = {
    defaultGreen: "green.500",
};