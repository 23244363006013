import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import userReducer from "./slices/user";
import userSaga from "./sagas/user";
import subjectReducer from "./slices/subject";
import subjectSaga from "./sagas/subject";
import contentReducer from "./slices/content";
import contentSaga from "./sagas/content";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: combineReducers({ user: userReducer, subject: subjectReducer, content: contentReducer }),
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(userSaga);
sagaMiddleware.run(subjectSaga);
sagaMiddleware.run(contentSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
