import { Method, REACT_APP_API_URL } from "../../api/base";
import { Callbacks } from "./types";

type CallFunction = (payload?: object) => void;

export default function useLazyRequest<T>(method: Method, relativeUrl: string, callbacks: Callbacks<T>): CallFunction {
    const call = (payload?: object) => {

        fetch(`${REACT_APP_API_URL}${relativeUrl}`,
            {
                method,
                credentials: 'include',
                body: payload ? JSON.stringify(payload) : undefined,
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
            .then(res => res.json())
            .then(data => {
                if (callbacks.onSuccess) {
                    callbacks.onSuccess(data);
                }
            })
            .catch(err => {
                if (callbacks.onError) {
                    callbacks.onError(err);
                }
            });
    };

    return call;
}