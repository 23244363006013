import React, { useEffect, useState } from "react";
import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import { AnswerFeedback } from "cordia-common/lib/types";
import { useParams } from "react-router-dom";
import { ErrorText, H1, H3, H3Gray } from "../../common/components/Texts";
import useLazyRequest from "../../common/hooks/useLazyRequest";
import { ContentKnowledge } from "../../common/types";
import KnowledgeCard from "../Content/components/KnowledgeCard";
import { useSelector } from "react-redux";
import { subjectState } from "../../store/slices/subject";

const RecapSubject = () => {
    const { subjectId } = useParams();
    const [{ data, hasError, isLoading }, getKnowledge] = useLazyRequest<
        ContentKnowledge[]
    >("GET", `/knowledge/recap/${subjectId}`);
    const { subjects } = useSelector(subjectState);
    const currentSubject = subjects.find(
        (subject) => subject._id === subjectId
    );
    const [knowledge, setKnowledge] = useState<ContentKnowledge[]>([]);
    const [currentKnowledge, setCurrentKnowledge] = useState<
        undefined | ContentKnowledge
    >();

    useEffect(() => {
        getKnowledge();
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            const first = data.pop();
            setCurrentKnowledge({ ...first } as ContentKnowledge);
            setKnowledge([...data]);
        } else {
            setCurrentKnowledge(undefined);
        }
    }, [data]);

    const onKnowledgeRecapped = (feedback: AnswerFeedback) => {
        if (currentKnowledge && feedback === "again") {
            let reinserted = [...knowledge];
            if (knowledge.length > 2) {
                reinserted.splice(2, 0, currentKnowledge);
            } else {
                reinserted.push(currentKnowledge);
            }

            setKnowledge([...reinserted]);
        }

        if (knowledge.length === 0) {
            getKnowledge();
        } else {
            const first = knowledge.pop();
            setCurrentKnowledge({ ...first } as ContentKnowledge);
        }
    };

    return (
        <VStack>
            <H1 text={`${currentSubject?.name}`} />
            {isLoading && <Spinner />}
            {!isLoading && hasError && (
                <ErrorText text="Something went wrong, please try again" />
            )}
            <Center h="30vh">
                <VStack spacing={10}>
                    {!currentKnowledge && (
                        <H3Gray text="There's no knowledge to be tested :)" />
                    )}
                    {currentKnowledge && (
                        <>
                            <H3 text='Please try to answer the knowledge cards, then press "Reveal Answer".' />
                            <H3Gray text="Be honest :)" />
                            <Box w={{ base: "98%", sm: "80%" }}>
                                <KnowledgeCard
                                    knowledge={currentKnowledge}
                                    onCompleted={onKnowledgeRecapped}
                                />
                            </Box>
                        </>
                    )}
                </VStack>
            </Center>
        </VStack>
    );
};

export default RecapSubject;
