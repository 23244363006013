import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { User } from "../../common/types";
import { LoginPayload } from "../types";
import { LoginResponse } from "../../api/user";

interface OwnState {
    user: User | null;
    isLoginIn: boolean;
    isLoadingMe: boolean;
    hasError: boolean;
    hasErrorLoadingMe: boolean;
}

const initialState: OwnState = {
    user: null,
    isLoginIn: false,
    isLoadingMe: false,
    hasError: false,
    hasErrorLoadingMe: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<LoginPayload>) => {
            state.user = null;
            state.isLoginIn = true;
            state.hasError = false;
        },
        loginCompleted: (state, action: PayloadAction<LoginResponse>) => {
            state.isLoginIn = false;
            state.user = action.payload.user;
        },
        loginFailed: (state) => {
            state.isLoginIn = false;
            state.user = null;
            state.hasError = true;
        },
        loadMe: (state) => {
            state.isLoadingMe = true;
            state.hasErrorLoadingMe = false;
        },
        loadMeCompleted: (state, action: PayloadAction<User>) => {
            state.isLoadingMe = false;
            state.user = action.payload;
            state.hasErrorLoadingMe = false;
        },
        loadMeFailed: (state) => {
            state.hasErrorLoadingMe = true;
            state.isLoadingMe = false;
        },
        logout: (state) => {
            state.isLoadingMe = true;
        },
        logoutSuccess: (state) => {
            state.isLoadingMe = false;
            state.user = null;
        }
    }
});

export const userActions = userSlice.actions;

export const userState = (state: RootState) => state.user;

export default userSlice.reducer;