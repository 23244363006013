import React, { useEffect, useState } from "react";
import { Spinner, useToast } from "@chakra-ui/react";
import { Subject } from "../../common/types";
import { H2Error } from "../../common/components/Texts";
import InputSubject from "./components/InputSubject";
import useLazyRequest from "../../common/hooks/useLazyRequest";
import { useDispatch, useSelector } from "react-redux";
import { subjectActions, subjectState } from "../../store/slices/subject";

interface OwnProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
}

const EditSubject = ({ id, isOpen, onClose }: OwnProps) => {
    const [hasUpdated, setHasUpdated] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const { isSaving, hasErrorSaving } = useSelector(subjectState);
    const [{ isLoading, hasError, data }, getSubject] = useLazyRequest<Subject>(
        "GET",
        `/subject/${id}`
    );

    useEffect(() => {
        if (id && isOpen) {
            getSubject();
        }
    }, [id, isOpen]);

    useEffect(() => {
        if (hasUpdated && !isSaving) {
            if (hasErrorSaving) {
                toast({
                    title: "Cannot update subject.",
                    description:
                        "We've encountered an error creating your subject. Please try again",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Subject updated.",
                    description: "We've successfully updated your subject.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        }
    }, [hasUpdated, isSaving, hasErrorSaving]);

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && hasError && (
                <H2Error text="Something went wrong: cannot get your subject. Please try again." />
            )}
            {!isLoading && !hasError && data && (
                <InputSubject
                    isOpen={isOpen}
                    isSubmitting={isSaving}
                    onClose={onClose}
                    onSave={(data) => {
                        setHasUpdated(true);
                        dispatch(
                            subjectActions.updateSubject({
                                id,
                                payload: data,
                            })
                        );
                    }}
                    defaultData={data}
                    onDelete={
                        () => {
                            dispatch(subjectActions.deleteSubject(id));
                            onClose();
                        }
                    }
                />
            )}
        </>
    );
};

export default EditSubject;
