export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export const { REACT_APP_API_URL } = process.env;

export default async function callApi<T>(method: Method, relativeUrl: string, payload?: object): Promise<T> {
    const url = `${REACT_APP_API_URL}${relativeUrl}`;
    const res = await fetch(url,
        {
            method,
            credentials: 'include',
            body: payload ? JSON.stringify(payload) : undefined,
            headers: {
                "Content-Type": "application/json",
            }
        }
    );

    return res.json();
}
