import React from "react";
import { Center, Spinner, Text } from "@chakra-ui/react";

const LoadingMe = () => (
    <Center h="80%">
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.100"
            color="green.500"
            size="xl"
        />
    </Center>
);

export default LoadingMe;
