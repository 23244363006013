import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import { Content, ContentKnowledge } from "../../../common/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSmile,
    faSadTear,
    faFaceMeh,
    faFaceLaughWink,
    faPencilAlt,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import useLazyRequest from "../../../common/hooks/useLazyRequest";
import { ErrorText } from "../../../common/components/Texts";
import { AnswerFeedback } from "cordia-common/lib/types";

const KnowledgeCard = ({
    knowledge,
    onCompleted,
}: {
    knowledge: ContentKnowledge;
    onCompleted?: (feedback: AnswerFeedback) => void;
}) => {
    const [revealAnswer, setRevealAnswer] = useState(false);
    const [editKnowledge, setEditKnowledge] = useState(false);
    const [answer, setAnswer] = useState(knowledge.answer);
    const [{ isLoading, hasError }, createAttempt] = useLazyRequest(
        "POST",
        `/knowledge/${knowledge._id}/answer`
    );

    const [
        { isLoading: isPatching, hasError: hasErrorPatching },
        updateKnowledge,
    ] = useLazyRequest("PATCH", `/knowledge/${knowledge._id}`);

    const publishAttempt = (feedback: AnswerFeedback) => {
        createAttempt({ feedback, timestamp: new Date().toISOString() });
        if (onCompleted) {
            onCompleted(feedback);
        }
    };

    useEffect(() => {
        setRevealAnswer(false);
        setAnswer(knowledge.answer);
    }, [knowledge]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            borderWidth="1px"
            borderRadius="lg"
            padding={4}
            overflow="hidden"
            w="100%"
        >
            {knowledge.content && (knowledge.content as Content).title && (
                <Text textColor="gray.500" alignSelf="center">
                    {(knowledge.content as Content).title}
                </Text>
            )}
            <Text textColor="gray.800" fontWeight="semibold" alignSelf="center">
                {knowledge.question}
            </Text>
            {revealAnswer && (
                <>
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        marginY={4}
                        p={2}
                        borderStyle="dashed"
                        backgroundColor="gray.100"
                    >
                        {!editKnowledge && (
                            <>
                                <Text textColor="gray.900">
                                    {hasErrorPatching ? knowledge.answer : answer}
                                </Text>
                                <Button
                                    rightIcon={
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    }
                                    onClick={() => {
                                        setEditKnowledge(true);
                                    }}
                                    size="xs"
                                >
                                    Edit
                                </Button>
                            </>
                        )}
                        {editKnowledge && (
                            <>
                            <Textarea defaultValue={answer} onChange={(e) => { setAnswer(e.target.value); }} />
                                <Button
                                    rightIcon={
                                        <FontAwesomeIcon icon={faCheck} />
                                    }
                                    size="xs"
                                    onClick={() => {
                                        updateKnowledge({ answer });
                                        setEditKnowledge(false);
                                    }}
                                    isLoading={isPatching}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </Box>
                    {!editKnowledge && (
                        <Stack
                            direction={["column", "row"]}
                            alignSelf="flex-end"
                        >
                            <Text fontSize="xs" textColor="gray.600">
                                How did you answer?
                            </Text>
                            <ButtonGroup>
                                <Button
                                    size="xs"
                                    colorScheme="green"
                                    onClick={() => {
                                        publishAttempt("easy");
                                    }}
                                    rightIcon={
                                        <FontAwesomeIcon
                                            icon={faFaceLaughWink}
                                        />
                                    }
                                    isLoading={isLoading}
                                >
                                    Easily
                                </Button>
                                <Button
                                    size="xs"
                                    colorScheme="blue"
                                    onClick={() => {
                                        publishAttempt("good");
                                    }}
                                    rightIcon={
                                        <FontAwesomeIcon icon={faSmile} />
                                    }
                                    isLoading={isLoading}
                                >
                                    Good
                                </Button>
                                <Button
                                    size="xs"
                                    colorScheme="orange"
                                    onClick={() => {
                                        publishAttempt("hard");
                                    }}
                                    rightIcon={
                                        <FontAwesomeIcon icon={faFaceMeh} />
                                    }
                                    isLoading={isLoading}
                                >
                                    Hardly
                                </Button>
                                <Button
                                    size="xs"
                                    colorScheme="red"
                                    onClick={() => {
                                        publishAttempt("again");
                                    }}
                                    rightIcon={
                                        <FontAwesomeIcon icon={faSadTear} />
                                    }
                                    isLoading={isLoading}
                                >
                                    Again, please
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    )}
                </>
            )}
            {!revealAnswer && (
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                        setRevealAnswer(true);
                    }}
                >
                    Reveal answer
                </Button>
            )}
            {!isLoading && hasError && (
                <ErrorText text="Something went wrong, please try again." />
            )}
        </Box>
    );
};

export default KnowledgeCard;
