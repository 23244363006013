import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { CreateContentPayload, getContentById, getContentBySubject, postContentForSubject, updateContent } from "../../api/content";
import { Content } from "../../common/types";
import { contentActions } from "../slices/content";
import { UpdateContent } from "../types";

function* callGetContent(contentId: PayloadAction<string>) {
    try {
        const response: Content = yield call(getContentById, contentId.payload);
        yield put(contentActions.getContentCompleted(response));
    } catch (err) {
        yield put(contentActions.getContentFailed);
    }
}

function* callGetContentForSubject(subjectId: PayloadAction<string>) {
    try {
        const response: Content[] = yield call(getContentBySubject, subjectId.payload);
        yield put(contentActions.loadContentCompleted(response));
    } catch (err) {
        yield put(contentActions.loadContentFailed);
    }
}

function* callPostContentForSubject(content: PayloadAction<CreateContentPayload>) {
    try {
        const response: Content =  yield call(postContentForSubject, content.payload);
        yield put(contentActions.postContentCompleted(response));
    } catch (err) {
        yield put(contentActions.postContentFailed);
    }
}

function* callPatchContent(action: PayloadAction<UpdateContent>) {
    try {
        const response: Content = yield call(updateContent, action.payload.id, action.payload.content);
        yield put(contentActions.updateContentCompleted(response));
    } catch (err) {
        yield put(contentActions.updateContentFailed());
    }
}

export default function* contentSagas() {
    yield takeLatest(contentActions.postContent.type, callPostContentForSubject);
    yield takeLatest(contentActions.updateContent.type, callPatchContent);
    yield takeLeading(contentActions.loadContent.type, callGetContentForSubject);
    yield takeLatest(contentActions.getContent.type, callGetContent);
}