import React from "react";
import {
    Button,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { User } from "../../../common/types";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/slices/user";
import { useNavigate } from "react-router-dom";

const UserMenu = ({ user }: { user: User }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            {user && (
                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        rounded="md"
                        variant="outline"
                    >
                        {user!.name.charAt(0)}
                    </MenuButton>
                    <MenuList p="4">
                        <Text textAlign="center" fontWeight="semibold">
                            Account
                        </Text>
                        <MenuDivider />
                        <Text textAlign="center" textColor="gray.900">
                            {user.name}
                        </Text>
                        <Text textAlign="center" textColor="gray.600">
                            {user.email}
                        </Text>
                        <MenuDivider />
                        <MenuItem
                            onClick={() => {
                                dispatch(userActions.logout());
                                navigate("/");
                            }}
                        >
                            Logout
                        </MenuItem>
                    </MenuList>
                </Menu>
            )}
        </>
    );
};

export default UserMenu;
